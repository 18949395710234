import React from "react";
import Seo from "../components/Seo/Seo";
import Layout from "../layout";
import Pratiques from "../components/Pratiques/Pratiques";
import Contact from "../components/Contact/Contact";
import config from "../../data/SiteConfig";
import { graphql } from "gatsby";
import GImage from "../components/Picture/GImage";

const PratiquesPage = ({ data: gqlData }) => {
  const { directus } = gqlData;
  return (
    <Layout>
      <div className="h-20 md:h-28"></div>
      {directus.settings &&
        directus.settings.pratiques_illustration !== null && (
          <GImage
            className="h-44 md:h-72 bg-pratiques bg-cover"
            src={directus.settings.pratiques_illustration.imageFile}
            alt="Image de la page des pratiques du secteur langues du Groupe Français d'éducation Nouvelle"
          />
        )}
      <div className="pratiques-container">
        <Seo
          title={`Pratiques`}
          description={config.siteDescription2}
          article={false}
        />
        {directus.categories && (
          <Pratiques
            title={directus.settings.practices_title}
            description={directus.settings.practices_description}
            categories={directus.categories}
            page={true}
          />
        )}
        {directus.contact && (
          <Contact contact={directus.contact} background={true} />
        )}
      </div>
    </Layout>
  );
};

export default PratiquesPage;

export const pageQuery = graphql`
  query {
    directus: allDirectus {
      categories(sort: "sort") {
        id
        title
      }
      settings {
        id
        practices_title
        practices_description
        pratiques_illustration {
          id
          imageFile {
            id
            childImageSharp {
              id
              gatsbyImageData(
                quality: 80
                layout: FULL_WIDTH
                placeholder: TRACED_SVG
                tracedSVGOptions: { color: "#d8b0b1" }
              )
            }
          }
        }
      }
      contact {
        title
        subtitle
        intro
        informations
        illustration {
          id
          imageFile {
            id
            childImageSharp {
              id
              gatsbyImageData(
                quality: 80
                layout: FULL_WIDTH
                placeholder: TRACED_SVG
                tracedSVGOptions: { color: "#d8b0b1" }
              )
            }
          }
        }
      }
    }
  }
`;
